import React, { Component } from 'react'

export default class Servicios extends Component {
  render () {
    return (
      <div className="page-servicios col col2 d-flex flex-column justify-content-center text-justify">
        <div className="p-4">
          <div className="row">
            <div className="col">

              <div className="h5">Negocio</div>
              <ul>
                <li>
                Contingencias
                </li>
                <li>
                Seguros de transporte
                </li>
                <li>
                Ciberriesgos
                </li>
                <li>
                Seguros de comercio
                </li>
                <li>
                Seguros para pyme
                </li>
                <li>
                Empresas e industria
                </li>
                <li>
                Construcción
                </li>
                <li>
                Servicio iberinform
                </li>
                <li>
                Seguros de accidentes de convenio
                </li>
                <li>
                Seguros de edificación
                </li>
                <li>
                Seguro de oficinas
                </li>
                <li>
                Seguro agrario
                </li>
              </ul>

              <div className="h5">Responsabilidad civil</div>
              <ul>
                <li>
                Empresas
                </li>
                <li>
                Construcción
                </li>
                <li>
                Profesional
                </li>
                <li>
                Privada
                </li>
                <li>
                Responsabilidad medioambiental
                </li>
                <li>
                Mascotas
                </li>
              </ul>

              <div className="h5">Vehículos</div>
              <ul>
                <li>
                Seguros de coche, furgonetas y motos
                </li>
                <li>
                Flotas
                </li>
                <li>
                Seguros de bicicleta
                </li>
                <li>
                Seguros náuticos
                </li>
                <li>
                Vehículos agrícolas
                </li>
                <li>
                Maquinaria y vehículos especiales
                </li>
                <li>
                Camiones
                </li>
              </ul>

              <div className="h5">Seguros de cancelación</div>
              <ul>
                <li>
                Reservas
                </li>
                <li>
                Eventos / Espectaculos
                </li>
                <li>
                Viajes
                </li>
              </ul>
            </div>

            <div className="col">
              <div className="h5">Seguros de proteccion y defensa juridica</div>
              <ul>
                <li>
                Empresarial
                </li>
                <li>
                Comunidades
                </li>
                <li>
                Privado
                </li>
              </ul>

              <div className="h5">
                Ahorros y jubilación
              </div>
              <ul>
                <li>
                  Planes de pensiones
                </li>
                <li>
                  Fondos de inversión
                </li>
                <li>
                  Seguros de ahorro
                </li>
                <li>
                  Rentas
                </li>
              </ul>

              <div className="h5">
                Vida
              </div>
              <ul>
                <li>
                  Seguros de vida
                </li>
                <li>
                  Seguro de accidentes
                </li>
                <li>
                  Decesos
                </li>
              </ul>

              <div className="h5">Hogar</div>
              <ul>
                <li>
                Comunidades
                </li>
                <li>
                Familia-hogar
                </li>
                <li>
                Impago de alquiler
                </li>
                <li>
                Caravanas / Modulos
                </li>
                <li>
                Casas de madera y prefabricadas
                </li>
                <li>
                Mascotas
                </li>
              </ul>

              <div className="h5">Salud</div>
              <ul>
                <li>
                Cobertura médica
                </li>
                <li>
                Reembolso
                </li>
                <li>
                Subsidio de hospitalización
                </li>
                <li>
                Cuidado personal
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
