import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

export default class Nav extends PureComponent {
  state = {
    navbarShown: false,
  }

  toggleNavbar = () => {
    this.setState({
      navbarShown: !this.state.navbarShown,
    })
  }

  render () {
    const { navbarShown } = this.state

    const links = [
      {
        to: '/quienes-somos',
        text: 'Nosotros',
      },
      {
        to: '/servicios',
        text: 'Servicios',
      },
      {
        to: '/contacto',
        text: 'Contacto',
      },
      {
        text: 'e-cliente',
        href: 'https://clientes.seguroscatalanaoccidente.com/',
      },
    ]

    const navbarLinks = links.map(link => {
      if (link.to) {
        return (
          <NavLink
            key={link.text}
            activeClassName="underline"
            className="nav-link"
            to={link.to}
          >
            {link.text}
          </NavLink>
        )
      } else {
        return (
          <a
            key={link.text}
            className="nav-link"
            href="https://clientes.seguroscatalanaoccidente.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.text}
          </a>
        )
      }
    })

    const mdLinks = links.map(link => {
      if (link.to) {
        return (
          <NavLink
            key={link.text}
            activeClassName="underline"
            className="p-2 text-dark h5"
            to={link.to}
          >
            {link.text}
          </NavLink>
        )
      } else {
        return (
          <a
            key={link.text}
            className="p-2 text-dark h5"
            href="https://clientes.seguroscatalanaoccidente.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.text}
          </a>
        )
      }
    })

    return (
      <>
        <nav className="d-md-none navbar navbar-expand-lg navbar-light bg-light">
          <a className="navbar-brand" href="#">Llort-Tarradas</a>
          <button
            onClick={this.toggleNavbar}
            className={`navbar-toggler ${!navbarShown && 'collapsed'}`}
            type="button"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className={`collapse navbar-collapse ${navbarShown && 'show'}`} id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                {navbarLinks[0]}
              </li>
              <li className="nav-item">
                {navbarLinks[1]}
              </li>
              <li className="nav-item">
                {navbarLinks[2]}
              </li>
            </ul>

            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                {navbarLinks[3]}
              </li>
            </ul>
          </div>
        </nav>

        <div className="nav p-2 pt-4 d-none d-sm-none d-md-block">
          <nav className="my-2 my-md-0 mr-md-3">
            <div className="d-flex">
              <div className="d-flex">
                {mdLinks[0]}
                {mdLinks[1]}
                {mdLinks[2]}
              </div>
              <div className="d-flex justify-content-end w-100 e-cliente">
                <span>
                  {mdLinks[3]}
                </span>

                <svg
                  className="bi bi-box-arrow-in-up-right"
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M14.5 3A1.5 1.5 0 0013 1.5H3A1.5 1.5 0 001.5 3v5a.5.5 0 001 0V3a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v10a.5.5 0 01-.5.5H9a.5.5 0 000 1h4a1.5 1.5 0 001.5-1.5V3z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4.5 6a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V6.5H5a.5.5 0 01-.5-.5z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M10.354 5.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </nav>
        </div>
      </>
    )
  }
}
