import React, { Component, Fragment } from 'react'

import Navbar from '../../Nav'


function withNavbarHOC (WrappedComponent) {
  return class WithNavbarHOC extends Component {
    render () {
      return <Fragment>
        <Navbar />
        <WrappedComponent {...this.props} />
      </Fragment>
    }
  }
}

export default withNavbarHOC
