import React, { Component } from 'react'

export default class Contacto extends Component {
  render () {
    const tlfs = [
      '+34 677 977 516',
      '+34 629 348 788',
      '+34 629 391 737',
    ]

    return (
      <div className="col col2 d-flex flex-column justify-content-center text-justify">
        <div className="p-4">
          <p className="text-center">
            <a
              className="h4"
              href="mailto:info@llort-tarradas.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@llort-tarradas.com
            </a>

            <br />
            <br />
            <br />

            <div className="h4">Dpto. comercial</div>
            <a
              className="h4"
              href={`tel:${tlfs[0]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {tlfs[0]}
            </a>
            <br />
            <a
              className="h4"
              href={`tel:${tlfs[1]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {tlfs[1]}
            </a>

            <br />
            <br />

            <div className="h4">Dpt. siniestros y administración</div>
            <a
              className="h4"
              href={`tel:${tlfs[3]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {tlfs[2]}
            </a>
          </p>
        </div>
      </div>
    )
  }
}
