import React, { Component } from 'react'

export default class QuienesSomos extends Component {
  render () {
    const years = new Date().getFullYear() - 2004

    return (
      <div className="col col2 d-flex flex-column justify-content-center text-justify">
        <div className="p-4">
          <p>
            LLORT-TARRADAS S.L. somos una agencia de Seguros exclusiva de Catalana Occidente
            con <strong>{years} años de experiencia</strong>.
          </p>

          <p>
            Os damos el servicio y la información necesaria para:
            <ul>
              <li>
                Asegurar vuestro <strong>hogar</strong>, <strong>vehículos</strong>,
                <strong> caravanas</strong>...
              </li>
              <li>
                <strong>Cobertura médica</strong>
              </li>
              <li>
                <strong>Vida protección</strong>
              </li>
            </ul>
          </p>

          <p>
            Como <strong>Asesores Financieros</strong>, les proponemos siempre la mejor
            opción para dar <strong>rentabilidad a sus ahorros</strong>.
          </p>

          <p>
            Y como <strong>expertos en Gerencia de Riesgos</strong> os ofrecemos para
            vuestras <strong>empresas</strong> productos específicos para dar <strong>
              la mejor cobertura a vuestra actividad</strong>.
          </p>

          <br />
          <br />

          <p>
            Nuestra filosofía es trabajar <strong>al lado de nuestro
            cliente</strong> para adaptarnos a sus necesidades e
            inquietudes, y sobretodo tener un <strong>trato personalizado</strong>.
          </p>
        </div>
      </div>
    )
  }
}
