import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import './styles/index.scss'

import QuienesSomos from './components/pages/quienes-somos/container'
import Servicios from './components/pages/servicios/container'
import Contacto from './components/pages/contacto/container'

const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
navigator.userAgent &&
navigator.userAgent.indexOf('CriOS') === -1 &&
navigator.userAgent.indexOf('FxiOS') === -1

if (isSafari) {
  const $app = document.getElementById('app')
  const appClassName = $app.className

  $app.className = appClassName.replace('d-flex', ' ')
}

ReactDOM.render(
  <Fragment>
    <BrowserRouter>
      <Switch>
        <Route exact path="/quienes-somos" component={QuienesSomos} />
        <Route exact path="/servicios" component={Servicios} />
        <Route exact path="/contacto" component={Contacto} />

        <Redirect exact from="/" to="/quienes-somos" />
      </Switch>
    </BrowserRouter>
  </Fragment>,
  document.getElementById('app'),
)
